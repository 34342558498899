import React, { Fragment, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import ActiveOptionsList from '../ActiveOptionsList';
import CustomSelect from '../../../../components/CustomSelect';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';
import usePrevious from '../../../../../../hooks/usePrevious';

import mapModuleToTerritoryCategoryGroups from '../../../../../../data/mapGroupTypeToTerritoryCategoryKey';

import { onCurrentLocaleCustom } from '../../../../../../utils/locale';
import toast from '../../../../../../utils/toast';

import getCustomClient from '../../../../../../lib/getCustomClient';

import styles from './DegradationViewModeControl.module.scss';

import {
  GET_BASE_DATA,
  GET_BASE_CLIENT_DATA,
  GET_TERRITORIES_LIST,
  GET_TERRITORY_BOUNDING_BOX,
  GET_TRANSITION_PERIOD_OPTIONS_DATA,
} from './query';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 14,
    color: 'white',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
}))(Tooltip);

const TERRITORY_CATEGORIES_WITH_SIMPLE_SELECT = [
  'country',
  'biome',
  'state',
  'special_territories',
  'biosphere_reserves',
  'geoparks',
];

export default function DegradationViewModeControl({
  clientType,
  territoryCategoriesGroup = 'default',
  isTransition = false,
  hideGroupType = false,
  showBufferField = false,
  showMultipleTerritories = true,
  showCrossing = false,
  inline,
  onFlyTo = () => {},
}) {
  const intl = useIntl()
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const { data: clientBaseData } = useQuery(GET_BASE_CLIENT_DATA);
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    client: getCustomClient(clientType)
  });
  const { data: transitionBaseData, loading: loadingTransitionBaseData } = useQuery(GET_TRANSITION_PERIOD_OPTIONS_DATA, {
    skip: !isTransition,
    client: getCustomClient(clientType)
  });
  const [
    loadTerritoriesListData,
    {
      data: territoriesListData,
      loading: loadingTerritoriesListData
    }
  ] = useLazyQuery(GET_TERRITORIES_LIST, {
    client: getCustomClient(clientType)
  });

  const loadTerritoryBoundingBox = (id) => {
    client.query({
      query: GET_TERRITORY_BOUNDING_BOX,
      variables: { territoriesIds: [id] }
    })
      .then((response) => {
        const bbox = _.get(response, 'data.territoriesByIds[0].territoryGeometry.boundingBox');

        if (bbox) {
          const parsedBBox = [
            [_.get(bbox, 'yMin'), _.get(bbox, 'xMin')],
            [_.get(bbox, 'yMax'), _.get(bbox, 'xMax')],
          ];

          onFlyTo(parsedBBox);
        }
      });
  };

  const territoryCategoriesList = _.get(baseData, 'territoryCategoriesList');
  const geometryBuffers = _.get(baseData, 'geometryBuffersList');
  const coverageTransitionPeriodOptionsList = _.get(transitionBaseData, 'coverageTransitionPeriodOptionsList');

  const territoriesList = _.get(territoriesListData, 'territoriesList');

  const activeModule = _.get(clientBaseData, 'app.activeModule');
  const activeModuleContent = _.get(clientBaseData, 'app.activeModuleContent');
  const baseParams = _.get(clientBaseData, 'app.baseParams');
  const showBeforeAndAfterMosaic = _.get(clientBaseData, 'app.showBeforeAndAfterMosaic');
  const groupType = _.get(baseParams, 'groupType') || 'territorial';
  const degradationType = _.get(baseParams, 'degradationType') || 'vector';
  const territoryType = _.get(baseParams, 'territoryType');
  const territoryValueType = _.get(baseParams, 'territoryValueType');
  const territoryIsMultiple = territoryValueType === 'multiple';
  const crossingEnabled = _.get(baseParams, 'crossingEnabled');
  const crossingTerritoryType = _.get(baseParams, 'crossingTerritoryType');
  const crossingTerritories = _.get(baseParams, 'crossingTerritories');
  const territories = _.get(baseParams, 'territories');
  const buffer = _.get(baseParams, 'buffer');
  const yearRange = _.get(baseParams, 'yearRange');

  const prevGroupType = usePrevious(groupType);

  const parsedTerritoryTypeOptions = _.map(territoryCategoriesList, (territoryType) => {
    const stringList = _.get(territoryType, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });

    return {
      id: territoryType.id,
      key: territoryType.key,
      label: _.get(selectedString, 'stringValue'),
      parentId: territoryType.parentId,
    };
  });
  const territoryCategoryGroups = mapModuleToTerritoryCategoryGroups[territoryCategoriesGroup];
  const territoryKeysByGroupType = _.get(territoryCategoryGroups, groupType);
  const filteredTerritoryTypeOptions = _(parsedTerritoryTypeOptions)
    .filter(({ key }) => _.includes(territoryKeysByGroupType, key))
    .sortBy(({ key }) => _.findIndex(territoryKeysByGroupType, (baseTerritoryKey) => baseTerritoryKey === key))
    .value();

  const territoryTypeOption = _.find(parsedTerritoryTypeOptions, { id: territoryType });
  const hasTerritorySimpleSelect = _.includes(TERRITORY_CATEGORIES_WITH_SIMPLE_SELECT, _.get(territoryTypeOption, 'key'));
  const crossingTerritoryTypeOptions = _.filter(parsedTerritoryTypeOptions, (option) => {
    return _.includes(option.key, 'crossing') && _.includes(option.key, _.get(territoryTypeOption, 'key'));
  });
  const crossingInputIsDisabled = _.isEmpty(crossingTerritoryTypeOptions) || territoryIsMultiple || _.isEmpty(territories);

  const parsedTerritoryOptions = _.map(territoriesList, (territory) => {
    const stringList = _.get(territory, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    // TODO: Remove bounding box property from territory data, since it is loaded and use only on map flyTo
    const boundingBox = {
      xMin: 0,
      xMax: 0,
      yMin: 0,
      yMax: 0,
    };
    // const boundingBox = _.get(territory, 'territoryGeometry.boundingBox');

    return {
      id: territory.id,
      label: _.get(selectedString, 'stringValue'),
      boundingBox: [
        [_.get(boundingBox, 'yMin'), _.get(boundingBox, 'xMin')],
        [_.get(boundingBox, 'yMax'), _.get(boundingBox, 'xMax')],
      ]
    };
  });

  const parsedCoverageTransitionPeriodOptionsList = _(coverageTransitionPeriodOptionsList)
    .map((item) => {
      const { fromYear, toYear, groupBy, orderAmongSiblings } = item;
      const parsedFromYear = Number(_.replace(fromYear, '_', ''));
      const parsedToYear = Number(_.replace(toYear, '_', ''));

      return {
        id: `${ parsedFromYear }-${ parsedToYear }`,
        label: `${ parsedFromYear } ${ formatMessage(`mapbiomas.header.base_form.year_range.separator`) } ${ parsedToYear }`,
        group: groupBy,
        orderAmongSiblings
      };
    })
    .orderBy(['group', 'orderAmongSiblings', 'id'])
    .groupBy('group')
    .value();

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const updateClientAppBaseParamsState = (params) => {
    updateClientAppState({
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...params
      }
    });
  };

  useEffect(() => {
    if (hideGroupType && groupType !== 'territorial') {
      updateClientAppBaseParamsState({
        groupType: 'territorial',
        territoryType: null,
        territorySubtype: null,
        territoryValueType: 'single',
        territory: null,
        territories: null,
      });
    }
  }, [hideGroupType, groupType]);

  useEffect(() => {
    if (territoryType) {
      loadTerritoriesListData({
        variables: {
          categoryId: territoryType
        }
      });
    }
  }, [territoryType]);

  useEffect(() => {
    if (territoryCategoriesList && !territoryType && groupType === 'territorial') {
      const initialTerritoryType = _.find(territoryCategoriesList, { key: 'country' });
      const territoryTypeId = _.get(initialTerritoryType, 'id');
      const params = {
        territoryType: territoryTypeId,
      };

      if (_.isNumber(territoryTypeId)) {
        // TODO: Replace this query to another that loads only active territories data
        loadTerritoriesListData({
          variables: {
            categoryId: territoryTypeId
          }
        });

        updateClientAppBaseParamsState(params);
      }
    }
  }, [territoryCategoriesList, territoryType]);

  useEffect(() => {
    if (_.isEmpty(territories) && territoriesList && _.size(territoriesList) === 1) {
      const territoryTypeOption = _.find(filteredTerritoryTypeOptions, { id: territoryType });

      const firstTerritory = _.first(territoriesList);
      const firstTerritoryId = _.get(firstTerritory, 'id');
      const territoryOption = _.find(parsedTerritoryOptions, { id: firstTerritoryId });

      const activeTerritoryIds = _.map(territories, 'id');

      if (!_.includes(activeTerritoryIds, firstTerritoryId)) {
        const newTerritoryItem = {
          __typename: 'AppBaseParamsActiveTerritoryItemState',
          id: firstTerritoryId,
          label: _.get(territoryOption, 'label'),
          territoryTypeId: _.get(territoryTypeOption, 'id'),
          territoryTypeLabel: _.get(territoryTypeOption, 'label'),
          territoryBBox: _.get(territoryOption, 'boundingBox'),
        };

        updateClientAppBaseParamsState({
          territories: _.concat(_.isNil(territories) ? [] : territories, newTerritoryItem)
        });

        loadTerritoryBoundingBox(firstTerritoryId);
      }
    }
  }, [activeModule, territoriesList]);

  useEffect(() => {
    if (territoryType && !_.isEmpty(filteredTerritoryTypeOptions) && prevGroupType === groupType) {
      const hasTerritoryTypeData = !!_.find(filteredTerritoryTypeOptions, { id: territoryType });

      if (!hasTerritoryTypeData) {
        updateClientAppBaseParamsState({
          territoryType: null,
          territories: []
        });
        toast(formatMessage('mapbiomas.toast.unavailable_territory'), 'info');
      }
    }
  }, [filteredTerritoryTypeOptions, territoryType, prevGroupType, groupType]);

  useEffect(() => {
    updateClientAppBaseParamsState({
      crossingTerritoryType: null,
      crossingTerritories: null,
    });
  }, [territoryType, territories, crossingEnabled, crossingInputIsDisabled]);

  useEffect(() => {
    updateClientAppBaseParamsState({
      crossingTerritories: null,
    });
  }, [crossingTerritoryType]);

  useEffect(() => {
    if (crossingEnabled && territoryIsMultiple) {
      updateClientAppBaseParamsState({
        crossingEnabled: false,
        crossingTerritoryType: null,
        crossingTerritories: null,
      });
    }
  }, [territoryIsMultiple, crossingEnabled]);

  useEffect(() => {
    updateClientAppBaseParamsState({
      crossingEnabled: false,
      crossingTerritoryType: null,
      crossingTerritories: null,
    });
  }, [activeModuleContent]);

  const handleInputChange = (field, inputValue) => {
    const value = _.isObject(inputValue) ? inputValue.target.value : inputValue;
    let params = { [field]: value };

    if (field === 'territoryType' && !territoryIsMultiple) {
      params.territories = [];
    }

    // if (field === 'yearRange') {
    //   const [minYear, maxYear] = _.split(inputValue, '-');

    //   updateClientAppState({
    //     timelineLimitsRange: [Number(minYear), Number(maxYear)]
    //   });
    // }

    updateClientAppBaseParamsState(params);
  };

  const handleGroupTypeChange = (event) => {
    updateClientAppBaseParamsState({ degradationType: event.target.value });
  };

  const handleTerritoryChange = (_field, value) => {
    let baseOption = value;

    if (!_.get(baseOption, 'id')) {
      baseOption = _.find(parsedTerritoryOptions, { id: value });
    }

    const territoryTypeOption = _.find(filteredTerritoryTypeOptions, { id: territoryType });
    const activeTerritoryIds = _.map(territories, 'id');

    if (!_.includes(activeTerritoryIds, _.get(baseOption, 'id'))) {
      const newTerritoryItem = {
        __typename: 'AppBaseParamsActiveTerritoryItemState',
        id: _.get(baseOption, 'id'),
        label: _.get(baseOption, 'label'),
        territoryTypeId: _.get(territoryTypeOption, 'id'),
        territoryTypeLabel: _.get(territoryTypeOption, 'label'),
        territoryBBox: _.get(baseOption, 'boundingBox'),
      };

      let nextTerritories = _.concat(_.isNil(territories) ? [] : territories, newTerritoryItem);

      if (!territoryIsMultiple) {
        nextTerritories = [newTerritoryItem];
      }

      updateClientAppBaseParamsState({
        territories: nextTerritories
      });
    }

    if (baseOption) {
      loadTerritoryBoundingBox(_.get(baseOption, 'id'));
    }

    handleInputChange('territory', _.get(baseOption, 'id'));
  };

  const handleCrossingTerritoryChange = (data) => {
    const territoryTypeOption = _.find(crossingTerritoryTypeOptions, { id: crossingTerritoryType });
    const activeTerritoryIds = _.map(crossingTerritories, 'id');

    if (!_.includes(activeTerritoryIds, _.get(data, 'id'))) {
      const newTerritoryItem = {
        __typename: 'AppBaseParamsActiveTerritoryItemState',
        id: _.get(data, 'id'),
        label: _.get(data, 'label'),
        territoryTypeId: _.get(territoryTypeOption, 'id'),
        territoryTypeLabel: _.get(territoryTypeOption, 'label'),
        territoryBBox: _.get(data, 'boundingBox'),
      };

      updateClientAppBaseParamsState({
        crossingTerritories: [newTerritoryItem]
      });
    }

    if (data) {
      loadTerritoryBoundingBox(_.get(data, 'id'));
    }

    handleInputChange('territory', _.get(data, 'id'));
  };

  const handleRemoveTerritory = (id) => {
    let territoriesClone = _.cloneDeep(territories);
    territoriesClone = _.filter(territoriesClone, (territory) => territory.id !== id);

    updateClientAppBaseParamsState({ territories: territoriesClone });
  };

  const toggleTerritoryValueType = () => {
    if (territoryIsMultiple) {
      updateClientAppBaseParamsState({
        territoryValueType: 'single',
        territories: [],
      });
    } else {
      updateClientAppBaseParamsState({ territoryValueType: 'multiple' });
    }
  };

  const toggleCrossing = () => {
    updateClientAppBaseParamsState({
      crossingEnabled: !crossingEnabled,
    });
  };

  const toggleBeforeAndAfterMosaic = () => {
    updateClientAppState({
      showBeforeAndAfterMosaic: !showBeforeAndAfterMosaic,
    });
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperInline]: inline
    }) }>
      <RadioGroup
        name="degradationType"
        value={ degradationType || null }
        onChange={ handleGroupTypeChange }
        className={ styles.radioGroup }
      >
        <FormControlLabel
          classes={{
            label: styles.radioLabel
          }}
          value="vector"
          control={ <Radio className={ styles.radioInput } size="small" color="primary" /> }
          label={ formatMessage('mapbiomas.header.base_form.degradation_type.options.vector') }
          id="territorial-vector"
        />
        <FormControlLabel
          classes={{
            label: styles.radioLabel
          }}
          value="crossing"
          control={ <Radio className={ styles.radioInput } size="small" color="primary" /> }
          label={ formatMessage('mapbiomas.header.base_form.degradation_type.options.crossing') }
          id="landowner-crossing"
        />
      </RadioGroup>
    </div>
  );
}
