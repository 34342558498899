import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import styles from './Accuracy.module.scss';
import { GET_ACCURACIES, GET_BIOMES, GET_CLASS_TREE_DATA, GET_LEVELS, GET_YEARS } from './query';
import LineChart from './LineChart';
import numbro from 'numbro';
import BarChart from './BarChart';

export default function Accuracy() {
  const [state, setState] = useState({
    level: 'l1',
    biome: 'Brasil',
    year: 2022,
  });
  const { data: biomes } = useQuery(GET_BIOMES);
  const { data: levels } = useQuery(GET_LEVELS);
  const { data: years } = useQuery(GET_YEARS);
  const { data: classTree } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default',
    },
  });
  const [getAccuracies, { data: accuracies, loading: loadingAccuracies }] = useLazyQuery(GET_ACCURACIES);

  useEffect(() => {
    getAccuracies({ variables: state });
  }, [state.year]);

  const handleSubmit = () => {
    getAccuracies({ variables: state });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ ...state, [id]: id === 'year' ? Number(value) : value });
  };

  const legend = _.keyBy(_.get(classTree, 'classTreeByKey[0].mvClassTreeLevelsUi', []), 'classId');
  const overallAccuracy = _.get(accuracies, 'accuracies.overallAccuracy', []);
  const userAccuracy = _.get(accuracies, 'accuracies.userAccuracy', []);
  const producerAccuracy = _.get(accuracies, 'accuracies.producerAccuracy', []);
  
  const confusionProdLeft = _.groupBy(_.get(accuracies, 'accuracies.confusionProdLeft', []), 'classId');
  const countProdLeftSeries = _.get(Object.values(confusionProdLeft), '[0].length', 0);
  const confusionProdRight = _.filter(_.get(accuracies, 'accuracies.confusionProdRight', []), (item) => item.classId !== -1);

  const confusionUserLeft = _.groupBy(_.get(accuracies, 'accuracies.confusionUserLeft', []), 'classId');
  const countUserLeftSeries = _.get(Object.values(confusionUserLeft), '[0].length', 0);
  const confusionUserRight = _.filter(_.get(accuracies, 'accuracies.confusionUserRight', []), (item) => item.classId !== -1);

  const omissionCategories = _.map(confusionProdRight, (item) => _.get(legend, `${item.classId}.defaultName`, item.classId));
  const insertionCategories = _.map(confusionUserRight, (item) => _.get(legend, `${item.classId}.defaultName`, item.classId));

  
  const omissionSeries = [
    {
      data: _.map(confusionProdRight, (item) => ({
        value: item.value,
        itemStyle: {
          color: _.get(legend, `${item.classId}.color`),
        }
      })),
    }
  ];
  for (let i = 0; i < countProdLeftSeries; i++) {
    omissionSeries.push({
      data: _.map(confusionProdLeft, (item) => ({
        value: item[i].value * -1,
        itemStyle: {
          color: _.get(legend, `${item[i].idClassMap}.color`),
        }
      })),
    });
  }

  const insertionSeries = [
    {
      data: _.map(confusionUserRight, (item) => ({
        value: item.value,
        itemStyle: {
          color: _.get(legend, `${item.classId}.color`),
        }
      })),
    }
  ];
  for (let i = 0; i < countUserLeftSeries; i++) {
    insertionSeries.push({
      data: _.map(confusionUserLeft, (item) => ({
        value: item[i].value * -1,
        itemStyle: {
          color: _.get(legend, `${item[i].idClassMap}.color`),
        }
      })),
    });
  }
  
  const overallYears = _.map(overallAccuracy, 'year');
  const overallData = [{ 
    name: 'Valor',
    color: '#00A00D',
    data: _.map(overallAccuracy, 'value') 
  }];
  const userYears = _.map(_.get(userAccuracy, '[0].statistics'), 'year');
  const userData = _.map(
    userAccuracy,
    (item) => ({
      name: _.get(legend, `${item.classId}.defaultName`, item.classId),
      color: _.get(legend, `${item.classId}.color`),
      data: _.map(item.statistics, 'value'),
    })
  );
  const producerYears = _.map(_.get(producerAccuracy, '[0].statistics'), 'year');
  const producerData = _.map(
    producerAccuracy,
    (item) => ({
      name: _.get(legend, `${item.classId}.defaultName`, item.classId),
      color: _.get(legend, `${item.classId}.color`),
      data: _.map(item.statistics, 'value'),
    })
  );
  
  return (
    <div className={ styles.container }>
      <p>Selecione o nível e o bioma nas caixas abaixo para visualização dos resultados estatísticos de acurácia da Coleção 8</p>
      <div className={ styles.searchContainer }>
        <div className={ styles.formControl }>
          <label for='biome'>Bioma</label>
          <select id='biome' onChange={ handleChange } value={ state.biome }>
            {
              _.get(biomes, 'accuracyBiomes', []).map((biome) => (
                <option value={ biome }>{ biome }</option>
              ))
            }
          </select>
        </div>
        <div className={ styles.formControl }>
          <label for='level'>Nível</label>
          <select id='level' onChange={ handleChange } value={ state.level }>
            {
              _.get(levels, 'accuracyLevels', []).map((level) => (
                <option value={ level }>Nível { level.replace(/\D/, '') }</option>
              ))
            }
          </select>
        </div>
        <div className={ styles.buttonContainer }>
          <button onClick={ handleSubmit } type='button'>Buscar</button>
        </div>
      </div>

      <div className={ styles.cards }>
        <div className={ styles.card }>
          <span>Acurácia geral</span>
          <span className={ styles.cardData }>{ numbro(_.get(accuracies, 'accuracies.globalAccuracy', 0)).format({ mantissa: 2}) }%</span>
        </div>
        <div className={ styles.card }>
          <span>Discordância de alocação</span>
          <span className={ styles.cardData }>{ numbro(_.get(accuracies, 'accuracies.allocationTot', 0)).format({ mantissa: 2}) }%</span>
        </div>
        <div className={ styles.card }>
          <span>Discordância de quantidade</span>
          <span className={ styles.cardData }>{ numbro(_.get(accuracies, 'accuracies.quantityTot', 0)).format({ mantissa: 2}) }%</span>
        </div>
      </div>

      <div className={ styles.lineCharts }>
        <div className={ styles.chart }>
          <p>Acurácia Geral</p>
          <LineChart categories={ overallYears } series={ overallData } />
        </div>
        <div className={ styles.chart }>
          <p>Erros de inclusão/omissão</p>
          <LineChart categories={ userYears } series={ userData } />
        </div>
        <div className={ styles.chart }>
          <p>Histórico da classe</p>
          <LineChart categories={ producerYears } series={ producerData } />
        </div>
      </div>

      <div className={ styles.formControl }>
        <label for='year'>Ano</label>
        <select id='year' onChange={ handleChange } value={ state.year }>
          {
            _.get(years, 'accuracyYears', []).map((year) => (
              <option value={ year }>{ year }</option>
            ))
          }
        </select>
      </div>

      <div className={ styles.barChart }>
        <p>Erros de inclusão</p>
        <BarChart categories={ insertionCategories } series={ insertionSeries } />
      </div>
      <div className={ styles.barChart }>
        <p>Erros de omissão</p>
        <BarChart categories={ omissionCategories } series={ omissionSeries } />
      </div>
    </div>
  );
}