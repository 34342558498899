import { gql } from 'graphql.macro';

export const GET_ACCURACIES = gql`
  query GetAccuracies($level: String!, $biome: String!, $year: Int!) {
    accuracies(level: $level, biome: $biome, year: $year) {
      quantityTot
      confusionUserRight {
        classId
        value
      }
      confusionUserLeft {
        classId
        idClassMap
        value
      }
      confusionProdRight {
        classId
        value
      }
      confusionProdLeft {
        classId
        idClassMap
        value
      }
      allocationTot
      globalAccuracy
      overallAccuracy {
        value
        year
      }
      producerAccuracy {
        classId
        statistics {
          value
          year
        }
      }
      userAccuracy {
        classId
        statistics {
          value
          year
        }
      }
    }
  }
`;

export const GET_BIOMES = gql`
  query AccuracyBiomes {
    accuracyBiomes
  }
`;

export const GET_LEVELS = gql`
  query AccuracyLevels {
    accuracyLevels
  }
`;

export const GET_YEARS = gql`
  query AccuracyYears {
    accuracyYears
  }
`;

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData($classTreeKey: String!) {
    classTreeByKey(key: $classTreeKey) {
      mvClassTreeLevelsUi {
        classId
        defaultName
        color
      }
    }
  }
`;
